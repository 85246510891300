import landing from "../assets/img/landing.jpg";
import about1 from "../assets/img/about1.jpg";
import about2 from "../assets/img/about2.jpg";
import about3 from "../assets/img/about3.jpg";
import logoYellow from "../assets/img/logo-yellow-sm.jpg";
import logoSlate from "../assets/img/logo-slate.jpg";

import photo1 from "../assets/img/photo-1.jpg";
import photo2 from "../assets/img/photo-2.jpg";
import photo3 from "../assets/img/photo-3.jpg";
import photo4 from "../assets/img/photo-4.jpg";
import photo5 from "../assets/img/photo-5.jpg";
import photo6 from "../assets/img/photo-6.jpg";
import photo7 from "../assets/img/photo-7.jpg";
import photo8 from "../assets/img/photo-8.jpg";
import photo9 from "../assets/img/photo-9.jpg";
import photo10 from "../assets/img/photo-10.jpg";
import photo11 from "../assets/img/photo-11.jpg";
import photo12 from "../assets/img/photo-12.jpg";
import photo13 from "../assets/img/photo-13.jpg";
import photo14 from "../assets/img/photo-14.jpg";
import photo15 from "../assets/img/photo-15.jpg";
import photo16 from "../assets/img/photo-16.jpg";
import photo17 from "../assets/img/photo-17.jpg";
import photo18 from "../assets/img/photo-18.jpg";
import photo19 from "../assets/img/photo-19.jpg";
import photo20 from "../assets/img/photo-20.jpg";
import photo21 from "../assets/img/photo-21.jpg";
import photo22 from "../assets/img/photo-22.jpg";
import photo23 from "../assets/img/photo-23.jpg";
import photo24 from "../assets/img/photo-24.jpg";
import photo25 from "../assets/img/photo-25.jpg";
import photo26 from "../assets/img/photo-26.jpg";
import photo27 from "../assets/img/photo-27.jpg";
import photo28 from "../assets/img/photo-28.jpg";
import photo29 from "../assets/img/photo-29.jpg";
import photo30 from "../assets/img/photo-30.jpg";
import photo31 from "../assets/img/photo-31.jpg";
import photo32 from "../assets/img/photo-32.jpg";
import photo33 from "../assets/img/photo-33.jpg";
import photo34 from "../assets/img/photo-34.jpg";
import photo35 from "../assets/img/photo-35.jpg";
import photo36 from "../assets/img/photo-36.jpg";
import photo37 from "../assets/img/photo-37.jpg";
import photo38 from "../assets/img/photo-38.jpg";
import photo39 from "../assets/img/photo-39.jpg";
import photo40 from "../assets/img/photo-40.jpg";
import photo41 from "../assets/img/photo-41.jpg";
import photo42 from "../assets/img/photo-42.jpg";
import photo43 from "../assets/img/photo-43.jpg";
import photo44 from "../assets/img/photo-44.jpg";
import photo45 from "../assets/img/photo-45.jpg";
import photo46 from "../assets/img/photo-46.jpg";
import photo47 from "../assets/img/photo-47.jpg";
import photo48 from "../assets/img/photo-48.jpg";
import photo49 from "../assets/img/photo-49.jpg";
import photo50 from "../assets/img/photo-50.jpg";
const gallery = [
  photo1,
  photo2,
  photo3,
  photo4,
  photo5,
  photo6,
  photo7,
  photo8,
  photo9,
  photo10,
  photo11,
  photo12,
  photo13,
  photo14,
  photo15,
  photo16,
  photo17,
  photo18,
  photo19,
  photo20,
  photo21,
  photo22,
  photo23,
  photo24,
  photo25,
  photo26,
  photo27,
  photo28,
  photo29,
  photo30,
  photo31,
  photo32,
  photo33,
  photo34,
  photo35,
  photo36,
  photo37,
  photo38,
  photo39,
  photo40,
  photo41,
  photo42,
  photo43,
  photo44,
  photo45,
  photo46,
  photo47,
  photo48,
  photo49,
  photo50,
];
export { about1, about2, about3, landing, logoSlate, logoYellow };
export default gallery;
