/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useCallback, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";
import VideoPlayer from "../api/videoPlayer";
import gallery, { landing, logoSlate, logoYellow } from "../api/photos";
import { intro } from "../api/videos";
import roomz from "../api/rooms";
import "./main.css";
export default function Index() {
  const openMenu = () => {
    document.getElementById("mobile-menu").classList.remove("hidden");
  };
  const closeMenu = () => {
    document.getElementById("mobile-menu").classList.add("hidden");
  };

  const [src, setSrc] = useState(null);
  const toggleModal = (e, item) => {
    e.preventDefault();
    setSrc(item);
    document.getElementById("modal").classList.toggle("hidden");
  };
  const [rooms, setRooms] = useState(roomz);
  const moreRooms = (e) => {
    e.preventDefault();
    setRooms(roomz);
    document.getElementById("morerooms").classList.toggle("hidden");
    document.getElementById("lessrooms").classList.toggle("hidden");
  };
  const lessRooms = (e) => {
    e.preventDefault();
    setRooms(roomz.slice(0, 6));
    document.getElementById("morerooms").classList.toggle("hidden");
    document.getElementById("lessrooms").classList.toggle("hidden");
  };

  const getRooms = useCallback(() => {
    return rooms.map((item, i) => {
      return (
        <div key={i} className="w-full shadow-lg xl:max-w-2xl bg-white sm:flex">
          <div
            className={`w-full sm:w-1/4 ${item.bg}  bg-center bg-cover border relative h-48 sm:h-auto shadow-inner`}
          >
            <div className="w-full absolute bottom-0 flex justify-center bg-gradient-black-transparent">
              <ul className="text-xs sm:font-semibold my-2 flex sm:block justify-around w-full sm:w-auto text-white">
                <li className="my-1 flex items-center">
                  <svg
                    aria-hidden={true}
                    focusable={false}
                    data-prefix="fas"
                    data-icon="wifi"
                    className="mr-1 h-3 w-3 fill-current"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z"
                    ></path>
                  </svg>
                  Free cancelation
                </li>
                <li className="my-1 flex items-center">
                  <svg
                    aria-hidden={true}
                    focusable={false}
                    data-prefix="fas"
                    data-icon="wifi"
                    className="mr-1 h-3 w-3 fill-current"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M127.1 146.5c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C111.8 5.9 105 0 96.8 0H80.4C70.6 0 63 8.5 64.1 18c3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1zm112 0c1.3 7.7 8 13.5 16 13.5h16.5c9.8 0 17.6-8.5 16.3-18-3.8-28.2-16.4-54.2-36.6-74.7-14.4-14.7-23.6-33.3-26.4-53.5C223.8 5.9 217 0 208.8 0h-16.4c-9.8 0-17.5 8.5-16.3 18 3.9 31.9 18 61.3 40.6 84.4 12 12.2 19.7 27.5 22.4 44.1zM400 192H32c-17.7 0-32 14.3-32 32v192c0 53 43 96 96 96h192c53 0 96-43 96-96h16c61.8 0 112-50.2 112-112s-50.2-112-112-112zm0 160h-16v-96h16c26.5 0 48 21.5 48 48s-21.5 48-48 48z"
                    ></path>
                  </svg>
                  Breakfast included
                </li>
                <li className="my-1 flex items-center">
                  <svg
                    aria-hidden={true}
                    focusable={false}
                    data-prefix="fas"
                    data-icon="wifi"
                    className="mr-1 h-3 w-3 fill-current"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="currentColor"
                      d="M634.91 154.88C457.74-8.99 182.19-8.93 5.09 154.88c-6.66 6.16-6.79 16.59-.35 22.98l34.24 33.97c6.14 6.1 16.02 6.23 22.4.38 145.92-133.68 371.3-133.71 517.25 0 6.38 5.85 16.26 5.71 22.4-.38l34.24-33.97c6.43-6.39 6.3-16.82-.36-22.98zM320 352c-35.35 0-64 28.65-64 64s28.65 64 64 64 64-28.65 64-64-28.65-64-64-64zm202.67-83.59c-115.26-101.93-290.21-101.82-405.34 0-6.9 6.1-7.12 16.69-.57 23.15l34.44 33.99c6 5.92 15.66 6.32 22.05.8 83.95-72.57 209.74-72.41 293.49 0 6.39 5.52 16.05 5.13 22.05-.8l34.44-33.99c6.56-6.46 6.33-17.06-.56-23.15z"
                    ></path>
                  </svg>
                  Wifi included
                </li>
              </ul>
            </div>
          </div>
          <div className="w-full sm:w-3/4 p-3">
            <div className="flex items-center border-b pb-3">
              <div>
                <div className="sm:flex items-center mb-1">
                  <h2 className="text-lg font-semibold text-slate-600">
                    Nyamakwere Lodges
                  </h2>
                </div>
              </div>
            </div>

            <div className="flex mt-3">
              <div>
                <svg
                  aria-hidden={true}
                  focusable={false}
                  data-prefix="fas"
                  data-icon="info-circle"
                  className="w-3 h-3 text-yellow-500 mt-1"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"
                  ></path>
                </svg>
              </div>
              <p className="text-xs ml-1 text-slate-600">{item.description}</p>
            </div>

            <div className="flex mt-3 items-center">
              <div>
                <svg
                  aria-hidden={true}
                  focusable={false}
                  data-prefix="fas"
                  data-icon="bed"
                  className="w-3 h-3 text-yellow-500"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                >
                  <path
                    fill="currentColor"
                    d="M176 256c44.11 0 80-35.89 80-80s-35.89-80-80-80-80 35.89-80 80 35.89 80 80 80zm352-128H304c-8.84 0-16 7.16-16 16v144H64V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v352c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16v-48h512v48c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V240c0-61.86-50.14-112-112-112z"
                  ></path>
                </svg>
              </div>
              <p className="text-xs ml-1 text-slate-600 font-semibold">
                {item.bedSetup}
              </p>
            </div>

            <div className="sm:flex mt-3 items-center justify-end">
              <div className="mt-3 sm:mt-3 sm:-mr-8">
                <a
                  href="https://api.whatsapp.com/send?phone=263717948787"
                  className="bg-yellow-500 shadow text-yellow-100 py-3 px-6 font-bold inline-block"
                >
                  Book Now
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    });
  }, [rooms]);

  const [photos, setPhotos] = useState(gallery.slice(0, 10));
  const morePhotos = (e) => {
    e.preventDefault();
    setPhotos(gallery);
    document.getElementById("morephotos").classList.toggle("hidden");
    document.getElementById("lessphotos").classList.toggle("hidden");
  };
  const lessPhotos = (e) => {
    e.preventDefault();
    setPhotos(gallery.slice(0, 10));
    document.getElementById("morephotos").classList.toggle("hidden");
    document.getElementById("lessphotos").classList.toggle("hidden");
  };
  const getImages = useCallback(() => {
    return photos.map((item, i) => {
      return (
        <a key={i} onClick={(e) => toggleModal(e, item)}>
          <img
            className="h-32 xs:h-48 md:h-64 w-full object-cover object-center"
            src={item}
            alt="gallery"
          />
        </a>
      );
    });
  }, [photos]);

  const [text, setText] = useState("");
  const [from, setFrom] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");

  const form = useRef();

  const handleSend = async (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_8s7ja0e",
        "template_bvf2prc",
        form.current,
        "9-f425fc0QB3EEIFL"
      )
      .then(
        (result) => {
          toggleToast("success-toast");
          console.log(result);
          setTimeout(() => {
            toggleToast("success-toast");
            e.target.reset();
          }, 5000);
        },
        (error) => {
          toggleToast("failure-toast");
          setTimeout(() => {
            toggleToast("failure-toast");
          }, 5000);
          console.log(error.text);
        }
      );
  };

  const toggleToast = (toastname) => {
    const toast = document.getElementById(toastname);
    toast.classList.toggle("hidden");
    toast.classList.toggle("flex");
  };
  useEffect(() => {
    const navLinks = document.querySelectorAll(".nav-link");
    const onNavLinkClick = () => {
      navLinks.forEach((element) => {
        element.addEventListener("click", (event) => {
          event.preventDefault();
          // [].forEach.call(navLinks, function (el) {
          //   el.classList.remove("active-link");
          //   el.classList.add("text-slate-600");
          // });
          // element.classList.add("active-link");
          // element.classList.remove("text-slate-600");
          const div = element.getAttribute("href");
          const view = document.querySelector(div);
          view.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "start",
          });
        });
      });
    };
    onNavLinkClick();

    // Check if the users browser supports these features
    const enhance =
      "querySelector" in document &&
      "addEventListener" in window &&
      "classList" in document.documentElement;

    // If the users browser browser supports the features we need, remove the no-enhance class from the html element and execute our video JS
    if (enhance) {
      document.documentElement.classList.remove("no-enhance");

      // Find all video molecules and instantiate a new instance of the Video class
      const videos = document.querySelectorAll(".js-video");
      Array.from(videos).forEach((video) => {
        const videoEl = new VideoPlayer(video);
        videoEl.pauseVideo();
      });
    }
  });

  return (
    <div className="relative">
      <div
        id="failure-toast"
        className="bg-slate-100 z-[1999] fixed hidden right-5 top-5 border-l-4 border-red-500 p-4 py-6 rounded shadow-lg items-center justify-between mb-6"
        role="alert"
      >
        <span className="fa-stack fa-2x sm:mr-2 mb-3">
          <i className="fas fa-circle text-red-700 fa-stack-2x"></i>
          <i className="fas fa-times fa-stack-1x text-white"></i>
        </span>
        <div className="sm:text-left text-center sm:mb-0 mb-3 w-128">
          <p className="font-bold mb-1 text-lg">Failure.</p>
          <p className="text-slate-500 inline-block">
            Your message failed to send. Please try again
          </p>
        </div>
        <i
          onClick={() => toggleToast("failure-toast")}
          className="fas fa-times mx-4 fa-2x text-slate-700"
        ></i>
      </div>
      <div
        className="bg-slate-100 hidden z-[1999] fixed right-5 top-5 border-l-4 border-green-500 p-4 py-6 rounded shadow-lg items-center justify-between mb-6"
        role="alert"
        id="success-toast"
      >
        <span className="fa-stack fa-2x sm:mr-2 mb-3">
          <i className="fas fa-circle text-green-700 fa-stack-2x"></i>
          <i className="fas fa-check fa-stack-1x text-white"></i>
        </span>
        <div className="sm:text-left text-center sm:mb-0 mb-3 w-128">
          <p className="font-bold mb-1 text-lg">Success.</p>
          <p className="text-slate-500 inline-block">
            Your message was sent successfully.
          </p>
        </div>
        <i
          onClick={() => toggleToast("success-toast")}
          className="fas fa-times mx-4 fa-2x text-slate-700"
        ></i>
      </div>
      <div className="relative z-50">
        <div className="cursor"></div>
        <div className="cursor-follower"></div>
      </div>
      <header className="my-0 mx-auto flex flex-col w-11/12">
        <div className="relative">
          <div className="bg-yellow-500  p-4 xl:p-6">
            <div className="container mx-auto flex items-center justify-between">
              <a href="#_" className="md:w-2/12">
                <img
                  className="h-8 w-20 md:w-full md:h-16 object-cover object-center"
                  src={logoYellow}
                  alt="logo"
                />
              </a>
              <div className="md:w-2/12 justify-end flex items-center space-x-4 xl:space-x-8">
                <div className="flex md:hidden">
                  <button
                    aria-label="open menu"
                    onClick={() => openMenu()}
                    className="text-black  md:hidden focus:outline-none focus:ring-2 rounded focus:ring-slate-600"
                  >
                    <svg
                      className="fill-stroke"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4 6H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M10 12H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 18H20"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              <ul className="hidden w-8/12 md:flex items-center justify-end space-x-8">
                <li>
                  <a
                    href="#home"
                    className="nav-link text-base text-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-800 hover:underline"
                  >
                    Home
                  </a>
                </li>
                <li>
                  <a
                    href="#about"
                    className="nav-link text-base text-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-800 hover:underline"
                  >
                    About
                  </a>
                </li>

                <li>
                  <a
                    href="#booking"
                    className="nav-link text-base text-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-800 hover:underline"
                  >
                    Booking
                  </a>
                </li>
                <li>
                  <a
                    href="#gallery"
                    className="nav-link text-base text-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-800 hover:underline"
                  >
                    Gallery
                  </a>
                </li>
                <li>
                  <a
                    href="#contact"
                    className="nav-link text-base text-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-800 hover:underline"
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            id="mobile-menu"
            className="hidden absolute  z-10 inset-0 md:hidden bg-yellow-500 flex-col h-72 w-full"
          >
            <div className="flex items-center justify-between border-b border-slate-900  pb-4 p-4">
              <button
                onClick={() => closeMenu()}
                aria-label="close menu"
                className="focus:outline-none focus:ring-2 rounded focus:ring-slate-600"
              >
                <svg
                  className="fill-stroke text-slate-800 "
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 4L4 12"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4 4L12 12"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-6 p-4">
              <ul className="flex flex-col space-y-6">
                <li>
                  <a
                    href="#"
                    className=" flex items-center justify-between hover:underline text-base text-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-800"
                  >
                    Home
                    <div>
                      <svg
                        className="fill-stroke text-black "
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.5 3L7.5 6L4.5 9"
                          stroke="currentColor"
                          strokeWidth="0.75"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className=" flex items-center justify-between hover:underline text-base text-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-800"
                  >
                    About
                    <div>
                      <svg
                        className="fill-stroke text-black "
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.5 3L7.5 6L4.5 9"
                          stroke="currentColor"
                          strokeWidth="0.75"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className=" flex items-center justify-between hover:underline text-base text-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-800"
                  >
                    Gallery
                    <div>
                      <svg
                        className="fill-stroke text-black "
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.5 3L7.5 6L4.5 9"
                          stroke="currentColor"
                          strokeWidth="0.75"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className=" flex items-center justify-between hover:underline text-base text-slate-800 focus:outline-none focus:ring-2 focus:ring-slate-800"
                  >
                    Booking
                    <div>
                      <svg
                        className="fill-stroke text-black "
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.5 3L7.5 6L4.5 9"
                          stroke="currentColor"
                          strokeWidth="0.75"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <main className="lg:p-5 lg:px-16">
        <section
          id="home"
          className="grid grid-cols-1 lg:flex lg:flex-row w-full h-full lg:h-[85vh] justify-around items-center overflow-hidden mb-8"
        >
          <div className="w-full lg:w-3/5 flex mt-auto items-center justify-center relative heroimg">
            <div className=" w-[87%] relative z-0 mt-6 hero-im">
              <img
                className="heroimg max-h-64 md:max-h-80 w-full z-[5] xl:max-h-96"
                src={landing}
                alt=""
              />
              <div className="absolute bottom-5 left-2 text-2xl md:text-4xl xl:text-6xl text-ellipsis w-3/5 lg:w-[45%] text-white textbx">
                Lasting impressions that you love.
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/5 flex flex-col lg:mr-0 lg:ml-6 p-5 lg:mt-8">
            <div className="flex flex-col lg:justify-end lg:items-end">
              <h1 className="mb-0 w-full text-2xl lg:text-5xl title text-left lg:text-center">
                Exciting for adventurous people
              </h1>
              <p className="my-1 w-full p-2 leading-normal text-sm xl:text-base font-extralight  text-slate-500">
                Relax, soak up the sun. You deserve it. Let our incredible
                views, one-of-a-kind activities and splendid services take your
                breath away.
              </p>
              <a
                href="https://api.whatsapp.com/send?phone=263717948787"
                className=" w-44 text-center lg:self-end border-2 border-yellow-500 text-yellow-500 m-0 py-1 px-4 xl:py-2 xl:px-6 font-bold text-lg xl:text-xl no-underline"
              >
                Book Now
              </a>
            </div>
          </div>
        </section>
        <section id="about" className="bg-slate-100 p-6 sm:p-10 lg:p-24">
          <div className="text-left lg:text-center mb-10 lg:mb-20">
            <h2 className="heading-secondary text-lg lg:text-3xl uppercase font-bold inline-block">
              Exciting for adventurous people
            </h2>
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-2 gap-10">
            <div className="mb-8 lg:mb-0">
              <h3 className="uppercase font-semibold text-sm lg:text-base mb-5">
                You're going to fall in love with nature
              </h3>
              <p className="text-sm text-slate-500 lg:text-base mb-5">
                Mutoko is well known for being the richest tantalite holder. The
                Budjas are well known as the largest producers of market
                gardening products/vegetables such as mapuno and nzungu
                (tomatoes and peanuts). This is where you get the freshest
                gardened vegetables. The Jatropha plant is also grown at cottage
                level they use it for making their own products for example
                diesel and soap.
              </p>

              <h3 className="uppercase font-semibold text-sm lg:text-base mb-5">
                Live adventures like you never have before
              </h3>
              <p className="text-sm lg:text-base mb-5">
                Come experience ChiBudja with Mabudja as they give you a
                tranquil African atmosphere and the personal attention you
                deserve.
              </p>

              <a href="#" className="btn-text">
                Learn more &rarr;
              </a>
            </div>
            <div className="flex justify-center items-center">
              <div className="group sm:w-[28rem] lg:w-full relative px-5 z-0 md:h-64 lg:h-96 xl:h-72 bg-yellow-500 m-video js-video rounded-2xl shadow-xl transition duration-500 hover:scale-105">
                <video
                  className="block w-full m-video__video h-full  js-video-video"
                  poster={landing}
                  loop
                  preload="auto"
                  controls
                  playsInline={true}
                >
                  <source src={intro} />
                </video>
                <button
                  className="a-button m-video__button js-video-play-button"
                  aria-pressed="true"
                  aria-label="Play video"
                  type="button"
                >
                  <svg
                    className="a-icon a-button__icon"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Play video</title>
                    <path d="M5.85355339,0.853553391 L12.2928932,7.29289322 C12.6834175,7.68341751 12.6834175,8.31658249 12.2928932,8.70710678 L5.85355339,15.1464466 C5.65829124,15.3417088 5.34170876,15.3417088 5.14644661,15.1464466 C5.05267842,15.0526784 5,14.9255015 5,14.7928932 L5,1.20710678 C5,0.930964406 5.22385763,0.707106781 5.5,0.707106781 C5.63260824,0.707106781 5.7597852,0.759785201 5.85355339,0.853553391 Z"></path>
                  </svg>
                </button>

                <button
                  className="group-hover:visible invisible a-button m-video__button js-video-pause-button"
                  aria-pressed="false"
                  aria-label="Pause video"
                  type="button"
                >
                  <svg
                    className="a-icon a-button__icon"
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Pause video</title>
                    <rect x="4" y="1" width="3" height="14" rx="1"></rect>
                    <rect x="9" y="1" width="3" height="14" rx="1"></rect>
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </section>

        <section id="booking" className="section-tours p-5">
          <div className="text-center my-8 lg:my-16">
            <h2 className="heading-secondary text-lg lg:text-3xl uppercase font-bold inline-block">
              Available Rooms
            </h2>
          </div>

          <div
            id="app"
            className="grid grid-cols-1 xl:grid-cols-2 gap-8 lg:gap-16 h-full p-0 sm:p-4"
          >
            {getRooms()}
          </div>

          <div className=" text-center my-8 lg:my-16">
            <a
              href="https://api.whatsapp.com/send?phone=263717948787"
              id="morerooms"
              className="btn btn--yellow nav-link"
            >
              Book now
            </a>
            <a
              onClick={moreRooms}
              href="#booking"
              id="morerooms"
              className="btn btn--yellow nav-link hidden -remove"
            >
              More Rooms
            </a>

            <a
              onClick={lessRooms}
              href="#booking"
              id="lessrooms"
              className="btn btn--yellow nav-link hidden"
            >
              Less Rooms
            </a>
          </div>
        </section>
        <section id="gallery" className="py-6 sm:py-10 px-8 lg:px-0 lg:py-24">
          <div className="text-center mb-10 lg:mb-20">
            <h2 className="heading-secondary text-lg lg:text-3xl uppercase font-bold inline-block">
              Gallery
            </h2>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5">
            {getImages()}
          </div>
          <div
            className="fixed z-10 overflow-y-auto top-0 bg-slate-900 w-full left-0 hidden"
            id="modal"
          >
            <div className="flex items-center justify-center min-height-100vh pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity">
                <div className="absolute inset-0 opacity-75" />
              </div>
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
                &#8203;
              </span>
              <div
                className="inline-block relative align-center rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle h-[95vh] sm:w-full"
                role="dialog"
                aria-modal="true"
                aria-labelledby="modal-headline"
              >
                <div className="w-full h-full">
                  <img
                    className=" w-full h-full object-contain object-center"
                    src={src}
                    alt="gallery"
                  />
                </div>
                <button
                  type="button"
                  className="p-4 absolute top-1 right-1 rounded-full text-white"
                  onClick={toggleModal}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>

          <div className=" text-center my-8 lg:my-16">
            <a
              onClick={morePhotos}
              href="#gallery"
              id="morephotos"
              className="btn btn--yellow nav-link"
            >
              More Photos
            </a>
            <a
              onClick={lessPhotos}
              href="#gallery"
              id="lessphotos"
              className="btn btn--yellow nav-link hidden"
            >
              Less Photos
            </a>
          </div>
        </section>

        <section
          id="contact"
          className="py-8 md:py-16 bg-gradient-to-r from-yellow-400 to-yellow-500"
        >
          <div className="mx-6 sm:mx-10 lg:mx-24">
            <div className="book">
              <div className="book__form">
                <form onSubmit={handleSend} ref={form} className="form">
                  <div className="mb-4 md:mb-8 lg:mb-16">
                    <h2 className="heading-secondary text-lg lg:text-3xl uppercase font-bold inline-block">
                      Get in touch
                    </h2>
                  </div>
                  <div className="form__group">
                    <label htmlFor="name" className="form__label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form__input capitalize"
                      placeholder="Full Name"
                      value={name}
                      onChange={(e) =>
                        setName(
                          e.target.value.replace(
                            /(^\w{1})|(\s+\w{1})/g,
                            (letter) => letter.toUpperCase()
                          )
                        )
                      }
                      id="name"
                      name="name"
                      required
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="email" className="form__label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form__input"
                      placeholder="Email address"
                      value={from}
                      onChange={(e) => setFrom(e.target.value)}
                      id="email"
                      name="email"
                      required
                    />
                  </div>
                  <div className="form__group capitalize">
                    <label htmlFor="subject" className="form__label">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form__input"
                      placeholder="Subject"
                      id="subject"
                      name="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form__group">
                    <label htmlFor="message" className="form__label">
                      Your Message
                    </label>
                    <textarea
                      placeholder="Your Message"
                      id="message"
                      name="message"
                      rows="5"
                      className="form__input"
                      value={text}
                      onChange={(e) => setText(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="form__group">
                    <button type="submit" className="btn btn--yellow">
                      Send Message &rarr;
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <a
        href="https://api.whatsapp.com/send?phone=263717948787"
        className="fixed w-[60px] h-[60px] p-3 right-5 bottom-5 sm:right-10  sm:bottom-10 text-center text-3xl text-white  bg-green-400  rounded-full shadow-lg hover:bg-green-600 "
      >
        <span className="fab fa-whatsapp"></span>
      </a>
      <footer className="bg-slate-700  pt-6">
        <div className="max-w-screen-lg px-4 sm:px-6 text-slate-400 text-sm sm:grid md:grid-cols-4 sm:grid-cols-2 mx-auto">
          <div className="p-5">
            <a
              href="#_"
              className="flex items-center mb-5 h-32 w-32 lg:h-40 lg:w-40 md:mb-0"
            >
              <img
                className="w-full h-full object-cover object-center"
                src={logoSlate}
                alt="logo"
              />
            </a>
          </div>
          <div className="p-5">
            <div className="uppercase text-yellow-500 font-bold">Links</div>
            <a href="#home" className="nav-link my-3 block hover:underline">
              Home
            </a>
            <a href="#about" className="nav-link my-3 block hover:underline">
              About
            </a>
            <a href="#booking" className="nav-link my-3 block hover:underline">
              Booking
            </a>
            <a href="#gallery" className="nav-link my-3 block hover:underline">
              Gallery
            </a>
            <a href="#contact" className="nav-link my-3 block hover:underline">
              Contact
            </a>
          </div>
          <div className="p-5">
            <div className="text-sm uppercase text-yellow-500 font-bold">
              Socials
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=263717948787"
              className=" my-3 block hover:underline"
            >
              <span className="fab fa-whatsapp mr-2"></span>
              Whatsapp
            </a>
            <a href="#" className=" my-3 block hover:underline">
              <span className="fab fa-facebook-f mr-2"></span> Facebook
            </a>
            <a href="#" className=" my-3 block hover:underline">
              <span className="fab fa-twitter mr-2"></span> Twitter
            </a>
          </div>
          <div className="p-5">
            <div className="text-sm uppercase text-yellow-500 font-bold">
              Contact us
            </div>

            <a className="my-3 block hover:underline" href="tel:+263717948787">
              +263717948787
            </a>
            <a className="my-3 block hover:underline" href="tel:+263787126885">
              +263787126885
            </a>
            <a
              className="my-3 block  hover:underline"
              href="mailto:reservations@nyamakwere.co.zw"
            >
              reservations@nyamakwere.co.zw
            </a>
          </div>
        </div>
      </footer>
    </div>
  );
}
