const rooms = [
  {
    id: 1,
    description: `
    Our rooms are elegantly furnished with beautiful furniture and include complimentary amenities pack, flat screen LCD TV and tea/coffee making facilities. Our king size provides views over landscaped gardens. It has a seating area, ample storage, digital safe and mini fridge.`,
    bedSetup: "Deluxe room, 1 King Bed",
    bg: "hotel-cover1",
  },
  {
    id: 2,
    description: `
    Our Deluxe  Large Double rooms also provides views over landscaped gardens. It has a seating area, digital safe and mini fridge.  These rooms also include complimentary amenities pack, flat screen LCD TV and tea/coffee making facilities.`,
    bedSetup: "Deluxe room, 1 Double Bed",
    bg: "hotel-cover2",
  },
  {
    id: 3,
    description: `
    Our Deluxe  Twin rooms are configured with either 2 single beds. These rooms also include complimentary amenities pack, flat screen LCD TV and tea/coffee making facilities.`,
    bedSetup: "Deluxe room, 2 Single Beds",
    bg: "hotel-cover3",
  },
  {
    id: 4,
    description: `
    Chalets rooms are configured with either 1 large double bed. These rooms also include complimentary amenities pack, flat screen LCD TV and tea/coffee making facilities.`,
    bedSetup: "Chalets room, 1 Double Bed",
    bg: "hotel-cover2",
  },
];

export default rooms;

/**[5:48 PM, 5/24/2022] Tich: 2 Executive rooms with kingsize beds
8 twin rooms
13 doubles

Maximum occupancy of 2 pax per room
[5:48 PM, 5/24/2022] Tich: All rooms have TV's, tea making facilities, and some have mini fridges (7).
The rooms have Wi-Fi coverage
[6:04 PM, 5/24/2022] Tich: Chalets rooms 6All rooms have TV's, tea making faculties.
The rooms havev1 double bed each and have Wi-Fi coveragem */
